<template>
  <section>
    <div id="upload-status-modal" class="uk-flex-top upload-status-modal uk-modal-container" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog uk-margin-auto-vertical">
          <button class="uk-modal-close-default" type="button" uk-close @click="hideModal" />
          <div class="uk-modal-header">
              <h2 class="uk-modal-title uk-text-center">Upload Status</h2>
          </div>
          <section class="uk-padding uk-padding-remove-bottom">
            <p class="uk-margin-remove">Upload Successful: <b>{{ data.success }} Dates</b></p>
            <div class="uk-flex uk-flex-between uk-flex-middle">
              <p class="uk-margin-remove">Error Found: <b>{{ data.failed }} Fields</b></p>
              <button
                class="uk-button uk-button-primary uk-text-bold uk-border-rounded uk-text-nowrap"
                @click="downloadFailedData"
              >
                <img :src="`${images}/shift-download-white.svg`" alt="" width="25" uk-img class="photo">
                Download
              </button>
            </div>
          </section>
          <section class="uk-card uk-card-default uk-padding">
            <div class="uk-overflow-auto">
              <table class="uk-table uk-table-medium uk-table-striped">
                <thead>
                  <tr>
                    <th class="uk-table-expand">USER ID</th>
                    <th class="uk-table-expand">NAMA</th>
                    <th class="uk-table-shrink">EMAIL</th>
                    <th class="uk-table-expand">ROLE</th>
                    <th class="uk-table-expand">OFFICE</th>
                    <th class="uk-table-expand">NOTES</th>
                  </tr>
                </thead>
                <tbody v-if="data.failed > 0">
                  <tr v-for="(user, index) in data.failedUser" :key="index">
                    <td>{{ user['USER ID'] }}</td>
                    <td>{{ user['NAMA'] }}</td>
                    <td>{{ user['EMAIL'] }}</td>
                    <td>{{ user['ROLE'] }}</td>
                    <td>{{ user['OFFICE'] }}</td>
                    <td>{{ user['NOTES'] }}</td>
                  </tr>
                </tbody>
                <empty-table v-else :colspan="7" />
              </table>
            </div>
            <div class="uk-modal-header" />
            <section class="uk-flex uk-flex-middle uk-flex-right">
              <button class="uk-button uk-button-primary uk-margin-top" type="button" @click="hideModal">Close</button>
            </section>
          </section>
      </div>
  </div>
  </section>
</template>

<script>
import EmptyTable from '@/components/globals/tables/EmptyTable';
import { PREFIX_IMAGE } from "@/utils/constant";
import { excelDownloader } from '@/utils/helper';

export default {
    props: {
        data: {
            type: Object,
            default: () => {},
        }
    },
    data() {
        return {
            images: PREFIX_IMAGE,
        };
    },
    components: {
        EmptyTable,
    },
    mounted() {
        window.UIkit.modal('#upload-status-modal').show();
    },
    methods: {
        hideModal() {
            window.UIkit.modal('#upload-status-modal').$destroy(true);
            this.$emit("hideModal");
        },
        downloadFailedData() {
            excelDownloader(this.data.failedUser, `Failed Verify Attendance.xlsx`);
        }
    }
};
</script>

<style scoped>
  thead {
    background: #0ABAB5;
  }

  th {
    color: #FFFFFF;
  }

  td {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
  }
</style>
